import React from 'react';
import styled from 'styled-components';
import { TOPIC_CATEGORY } from '../../../../mobx/TopicStore';
import { JOB_SUB_CATEGORY } from '../../../../constants/category/job';

const MetaContentStyle = styled.div`
  .content-header {
    width: 100%;
    height: 40px;
    background-color: white;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-left: 10px;
    }
  }
  .content {
    width: 100%;
    margin-top: 1px;
    background-color: white;
    padding: 12px 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    word-break: break-all;
    p {
      line-height: 20px;
    }
    .view-count {
      font-size: 14px;
      color: #9b9b9b;
      text-align: end;
    }
  }
`;

const MetaContent = props => {
  const { content, topic } = props;
  return (
    <MetaContentStyle>
      <div className="content-header">
        <span>
          {topic.category_name === TOPIC_CATEGORY.JOB &&
          topic.sub_category_name === JOB_SUB_CATEGORY.QIUZHI
            ? '个人介绍'
            : '详情描述'}
        </span>
      </div>
      <div className="content">
        {content &&
          content.split('\n').map((line, key) => <p key={key}>{line}</p>)}
        {topic && topic.category_name === TOPIC_CATEGORY.SOCIAL && (
          <p className="view-count">{topic.view_count}浏览</p>
        )}
      </div>
    </MetaContentStyle>
  );
};
export default MetaContent;
