import React from 'react';
import { Flex } from 'antd-mobile';
import styled from 'styled-components';
import Router from 'next/router';

const Left = styled.div`
  background-color: rgb(145, 213, 255);
  height: 75px;
  margin: 10px 0px 10px 10px;
  border-radius: 5px;
  padding: 10px;
`;

const Right = styled.div`
  background-color: rgb(183, 235, 143);
  height: 75px;
  margin: 10px 10px 10px 0;
  border-radius: 5px;
  padding: 10px;
`;

const Title = styled.div`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bolder;
  margin-top: 5px;
`;

const SubTitle = styled.div`
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 10px;
`;

const CardMenu = () => {
  return (
    <Flex style={{ backgroundColor: 'white', marginTop: 5 }}>
      <Flex.Item>
        <Left
          onClick={() => {
            Router.push('/group');
          }}
        >
          <Title>小组</Title>
          <SubTitle>万人小组 等你加入</SubTitle>
        </Left>
      </Flex.Item>
      <Flex.Item>
        <Right
          onClick={() => {
            Router.push('/merchant');
          }}
        >
          <Title>商家黄页</Title>
          <SubTitle>涵盖本地商圈</SubTitle>
        </Right>
      </Flex.Item>
    </Flex>
  );
};

export default CardMenu;
