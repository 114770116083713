import React from 'react';
import styled from 'styled-components';
import { getImageByID } from '../../util';

const GroupContainer = styled.div`
  height: 140px;
  width: 140px;
  background-size: 100% 100%;
  position: relative;
  margin: 5px;
  display: inline-block;
  border-radius: 5px;
  padding: 5px;
  background: url(${props => getImageByID(props.bg)}) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
`;
const BottomContainer = styled.div`
  height: 86px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;
const TitleContainer = styled.div`
  margin-top: 5px;
  font-size: 12px;
  height: 17px;
  line-height: 17px;
  font-weight: bold;
  padding-left: 5px;
`;

const UserCountContainer = styled.div`
  margin-top: 5px;
  font-size: 12px;
  padding-left: 5px;
`;
const OperationContainer = styled.div`
  margin-top: 10px;
  text-align: center;
  border-radius: 3px;
  height: 27px;
  line-height: 25px;
  border: 1px solid rgb(151, 151, 151);
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bolder;
`;

function GroupCard(props) {
  const buttonClicked = async () => {
    if (props.onClicked) {
      props.onClicked();
    }
  };

  return (
    <GroupContainer bg={props.data.avatar}>
      <BottomContainer>
        <TitleContainer>{props.data.name}</TitleContainer>
        <UserCountContainer>{props.data.userCount} 人</UserCountContainer>
        <OperationContainer onClick={buttonClicked}>
          {props.data.inGroup ? '进入小组' : '申请加入'}
        </OperationContainer>
      </BottomContainer>
    </GroupContainer>
  );
}

export default GroupCard;
