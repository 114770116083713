import React from 'react';
import styled from 'styled-components';

const SectionHeaderStyle = styled.div`
  .section-header {
    width: 100%;
    min-height: 40px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
    }
    span:first-child {
      color: rgba(0, 0, 0, 0.45);
      margin-left: 10px;
    }
    span:last-child {
      color: rgba(0, 0, 0, 0.85);
      margin: 5px 10px;
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
    }
  }
`;

const SectionHeader = props => {
  const { title, value, marginTop = 5 } = props;
  return (
    <SectionHeaderStyle>
      <div className="section-header" style={{ marginTop: `${marginTop}px` }}>
        <span>{title}</span>
        <span>{value}</span>
      </div>
    </SectionHeaderStyle>
  );
};

export default SectionHeader;
