// import zhengZu from '../../static/category/rental/01.png';
// import heZu from '../../static/category/rental/02.png';
// import shangPu from '../../static/category/rental/03.png';
// import qiuZu from '../../static/category/rental/04.png';
// import zhaoShiYou from '../../static/category/rental/05.png';

export const huXingConfig = [
  [
    { label: '不限', value: '' },
    { label: '1 房', value: '1' },
    { label: '2 房', value: '2' },
    { label: '3 房', value: '3' },
    { label: '4 房', value: '4' },
    { label: '5 房', value: '5' },
    { label: '6 房', value: '6' },
    { label: '7 房', value: '7' }
  ],
  [
    { label: '不限', value: '' },
    { label: '1 卫', value: '1' },
    { label: '2 卫', value: '2' },
    { label: '3 卫', value: '3' },
    { label: '4 卫', value: '4' },
    { label: '5 卫', value: '5' }
  ],
  [
    { label: '不限', value: '' },
    { label: '1 车位', value: '1' },
    { label: '2 车位', value: '2' },
    { label: '3 车位', value: '3' },
    { label: '4 车位', value: '4' },
    { label: '5 车位', value: '5' }
  ]
];

export const zuShouType = [
  { label: '出租', value: '出租' },
  { label: '出售', value: '出售' }
];

export const shangPuType = [
  { label: '流动摊位', value: '流动摊位' },
  { label: '写字楼', value: '写字楼' },
  { label: '店铺', value: '店铺' },
  { label: '柜台', value: '柜台' }
];

export const status = [
  { label: '经营中', value: '经营中' },
  { label: '空置中', value: '空置中' }
];

export const priceConfig = [
  {
    value: '',
    label: '不限'
  },
  {
    value: '0',
    label: '小于 150'
  },
  {
    value: '1',
    label: '150 - 200'
  },
  {
    value: '2',
    label: '200 - 300'
  },
  {
    value: '3',
    label: '300 - 500'
  },
  {
    value: '4',
    label: '500 - 800'
  },
  {
    value: '5',
    label: '大于 800'
  }
];

export const RENTAL_SUB_CATEGORY = {
  ZHENGZU: 'zhengzu',
  HEZU: 'hezu',
  SHANGPU: 'shangpu',
  QIUZU: 'qiuzu',
  ZHAOSHIYOU: 'zhaoshiyou'
};

export const level_2 = [
  {
    label: '整租',
    value: RENTAL_SUB_CATEGORY.ZHENGZU,
    icon: '../../static/category/rental/01.png'
  },
  {
    label: '合租',
    value: RENTAL_SUB_CATEGORY.HEZU,
    icon: '../../static/category/rental/02.png'
  },
  {
    label: '商铺',
    value: RENTAL_SUB_CATEGORY.SHANGPU,
    icon: '../../static/category/rental/03.png'
  },
  {
    label: '求租',
    value: RENTAL_SUB_CATEGORY.QIUZU,
    icon: '../../static/category/rental/04.png'
  },
  {
    label: '找室友',
    value: RENTAL_SUB_CATEGORY.ZHAOSHIYOU,
    icon: '../../static/category/rental/05.png'
  }
];

export const livingDescriptionData = [
  { label: '独立卫浴', value: '独立卫浴', checked: false },
  { label: '独立出入', value: '独立出入', checked: false },
  { label: '可养宠物', value: '可养宠物', checked: false },
  { label: '独立车位', value: '独立车位', checked: false },
  { label: '包含水费', value: '包含水费', checked: false },
  { label: '包含电费', value: '包含电费', checked: false },
  { label: '包含宽带', value: '包含宽带', checked: false },
  { label: '基本家具', value: '基本家具', checked: false }
];

export const owner = [
  { label: '房东', value: 'fangdong' },
  { label: '中介', value: 'zhongjie' }
];

export const roomType = [
  { label: '单人间', value: '单人间' },
  { label: '双人间', value: '双人间' },
  { label: '主人套', value: '主人套' }
];

export const roomNum = [
  { label: '1间', value: '1' },
  { label: '2间', value: '2' },
  { label: '3间', value: '3' }
];

export const personNum = [
  { label: '1人', value: '1' },
  { label: '2人', value: '2' },
  { label: '3人', value: '3' },
  { label: '4人', value: '4' },
  { label: '大于4人', value: '5' }
];

export const rentType = [
  { label: '求租整套', value: '求租整套' },
  { label: '求租单间', value: '求租单间' }
];

export const genderPreference = [
  { label: '男女不限', value: 'noMatter' },
  { label: '仅限男生', value: 'male' },
  { label: '仅限女生', value: 'female' }
];
