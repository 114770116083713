import styled from 'styled-components';

const FeedComments = ({ name, commentContent, commentsCount, onItemClick }) => {
  return commentsCount > 0 ? (
    <StyledDiv onClick={onItemClick}>
      <div className="item">
        <h4> {name}:</h4>
        <span className="comments-content">{commentContent}</span>
      </div>
      {commentsCount > 1 && (
        <div className="item line">
          <h4>{`查看全部${commentsCount}条评论`}</h4>
        </div>
      )}
    </StyledDiv>
  ) : null;
};

const StyledDiv = styled.div`
  margin-top: 8px;
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: 0 8px 0 8px;
  font-size: 12px;
  .comments-content {
    margin-left: 8px;
  }
  .item {
    padding: 8px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  h4 {
    display: inline;
    color: rgba(0, 0, 0, 0.65);
  }
  .line {
    border-top: solid 1px #fff;
  }
`;

export default FeedComments;
