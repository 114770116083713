import styled from 'styled-components';

export const HomeCateLinkContainer = styled.div`
  .HomeCateLink {
    height: 170px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 2px;
    .secondary-category {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;
      margin-top: 10px;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        width: 60px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        margin-top: 5px;
        text-align: center;
      }
    }
  }
`;
