import styled from 'styled-components';

export const CheliangMetaContainer = styled.div`
  .cheliang-specs {
    background-color: white;
    width: 100%;
    height: 236px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    .spec-container {
      width: 33%;
      height: 30%;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      .spec-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.45);
      }
      p {
        margin-bottom: 8px;
        .iconfont {
          font-size: 32px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
      span {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        font-weight: bold;
      }
    }
  }

  .specs-highlight {
    width: 100%;
    background-color: white;
    margin-top: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-left: 10px;
      margin-top: 10px;
    }
    .feature-container {
      margin-top: 10px;
      margin-right: 10px;
      max-width: 230px;
      display: flex;
      flex-wrap: wrap;
      .feature {
        width: 76px;
        margin-bottom: 10px;
        span {
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
        }
      }
    }
  }
`;
