import React, { Component } from 'react';
import { withRouter } from 'next/router';
import Router from 'next/router';
import HeaderMobile from '../presentational/HeaderMobile';

class HeaderMobileContainer extends Component {
  onSearch = () => {
    Router.push('/searchmiddlepage');
  };
  render() {
    return <HeaderMobile onSearch={this.onSearch} />;
  }
}

export default HeaderMobileContainer;
