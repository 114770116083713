import React, { Component } from 'react';
import { FeedsCardContainer } from './FeedsCardStyle';
import Link from 'next/link';
import {
  getImageAvatar,
  getImageMobileFull,
  getImageMobileList
} from '../../util';
import { inject, observer } from 'mobx-react';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const avatarImg = '../../static/avatar.png';
const Comment = styled.div`
  display: flex;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 5px;
  padding: 5px 0;
  .comment-icon {
    font-size: 15px;
    margin-right: 5px;
  }
`;

@inject('uiStore')
@observer
class ArticleFeedCard extends Component {
  state = {
    lightboxIsOpen: false
  };

  openLightbox = (event, index = 0) => {
    event.preventDefault();
    const { article } = this.props;
    const pswpElement = document.querySelectorAll('.pswp')[0];
    // build items array
    const items = article.images.map(image => {
      return {
        src: getImageMobileFull(image),
        w: 800,
        h: 1200
      };
    });

    const options = {
      index: index
    };

    // Initializes and opens PhotoSwipe
    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      items,
      options
    );
    gallery.init();
  };

  render() {
    const {
      article: { title, content, time, id, images, hasComment = false },
      article
    } = this.props;
    const user = this.props.article.user || this.props.user;
    let name,
      avatar = '';

    name = user && user.name ? user.name : '神马用户';
    if (user && user.image) {
      avatar = getImageAvatar(user && user.image);
    } else {
      avatar = avatarImg;
    }

    const TopicTitle = title === '' ? null : <h4>{title}</h4>;

    const tmp = document.createElement('DIV');
    tmp.innerHTML = content;
    const hideHTML = tmp.textContent || tmp.innerText || '';
    return (
      <FeedsCardContainer className="FeedsCardContainer">
        <Link href="/article/[id]" as={`/article/${id}`} scroll>
          <div>
            <div className="feeds-topic-header">
              <div className="feeds-topic-owner-info">
                <div>
                  <img src={avatar} alt="" />
                </div>
                <div className="feeds-topic-owner-username">
                  <span>{name}</span>
                  <span>更新于 {time}</span>
                </div>
              </div>
              <div className="feeds-topic-title">{TopicTitle}</div>
            </div>
            {(content && content.length > 0) ||
            (images && images.length > 0) ? (
              <div className="feeds-topic-main">
                <div className="ellipsis">
                  <div className="ellipsis-container">
                    <div className="ellipsis-content">{hideHTML}</div>
                    <div className="ellipsis-ghost">
                      <div className="ellipsis-placeholder" />
                      <div className="ellipsis-more">
                        {hideHTML && '...详情'}
                      </div>
                    </div>
                  </div>
                </div>
                {images && images.length > 0 && images.length !== 1 ? (
                  <div className="feeds-topic-image-square">
                    {images.slice(0, 6).map((image, i) => (
                      <div
                        key={i}
                        className="feeds-topic-image-square-item"
                        onClick={e => this.openLightbox(e, i)}
                      >
                        <LazyLoadImage
                          alt={''}
                          src={getImageMobileList(image)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="feeds-topic-image">
                    {images && images.length === 1 && (
                      <div className="feeds-topic-image-one">
                        <LazyLoadImage
                          onClick={e => this.openLightbox(e)}
                          src={getImageMobileList(images[0])}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Link>
      </FeedsCardContainer>
    );
  }
}

export default ArticleFeedCard;
