import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  height: 42px;
  background-color: white;
  margin: 5px 0 1px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin-left: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftIcon = styled.div`
  width: 3px;
  height: 20px;
  background-color: #ff8000;
`;

const LeftTitle = styled.div`
  width: 90px;
  height: 20px;
  line-height: 20px;
  margin-left: 7px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

const RightBtn = styled.div`
  height: 20px;
  line-height: 20px;
  display: inline-block;
  margin-right: 10px;
`;

const SectionHeader = props => {
  const { contentText, rightBtnText, rightBtnOnClick } = props;
  return (
    <Header>
      <ContentContainer>
        <LeftContainer>
          <LeftIcon />
          <LeftTitle>{contentText}</LeftTitle>
        </LeftContainer>
        {rightBtnText && (
          <RightBtn
            onClick={() => {
              if (rightBtnOnClick) {
                rightBtnOnClick();
              }
            }}
          >
            {rightBtnText} >
          </RightBtn>
        )}
      </ContentContainer>
    </Header>
  );
};

export default SectionHeader;
