import React from 'react';
import { Icon } from 'antd-mobile';
import styled from 'styled-components';
import Link from 'next/link';
// import logo from '../static/shenma.png';
import { TOPIC_CATEGORY } from '../mobx/TopicStore';
import Router from 'next/router';
import { inject, observer } from 'mobx-react';
import { ERSHOU_SUB_CATEGORY } from '../constants/category/usedItems';
import { RENTAL_SUB_CATEGORY } from '../constants/category/rental';
import { SERVICE_SUB_CATEGORY } from '../constants/category/localServices';
import { JOB_SUB_CATEGORY } from '../constants/category/job';

const logo = '/static/shenma.png';

export const generalPlaceHolder = (category_name, sub_category_name) => {
  const defaultHolder = '点击搜索神马好物';
  if (category_name) {
    let cateCN = '';
    switch (category_name) {
      case TOPIC_CATEGORY.CAR: {
        cateCN = '车辆买卖';
        break;
      }
      case TOPIC_CATEGORY.FANG: {
        switch (sub_category_name) {
          case RENTAL_SUB_CATEGORY.ZHENGZU: {
            cateCN = '整租';
            break;
          }

          case RENTAL_SUB_CATEGORY.HEZU: {
            cateCN = '合租';
            break;
          }

          case RENTAL_SUB_CATEGORY.SHANGPU: {
            cateCN = '商铺';
            break;
          }

          case RENTAL_SUB_CATEGORY.QIUZU: {
            cateCN = '求租';
            break;
          }

          case RENTAL_SUB_CATEGORY.ZHAOSHIYOU: {
            cateCN = '找室友';
            break;
          }
          default: {
            cateCN = '租房中心';
          }
        }
        break;
      }
      case TOPIC_CATEGORY.JOB: {
        switch (sub_category_name) {
          case JOB_SUB_CATEGORY.ZHAOPIN: {
            cateCN = '招聘';
            break;
          }

          case JOB_SUB_CATEGORY.QIUZHI: {
            cateCN = '求职';
            break;
          }

          default: {
            cateCN = '招聘求职';
          }
        }
        break;
      }
      case TOPIC_CATEGORY.SERVICE: {
        switch (sub_category_name) {
          case SERVICE_SUB_CATEGORY.JIAZHENGFUWU: {
            cateCN = '家政服务';
            break;
          }
          case SERVICE_SUB_CATEGORY.ZHUANGXIUWEIHU: {
            cateCN = '装修维护';
            break;
          }
          case SERVICE_SUB_CATEGORY.QICHEBAOYANG: {
            cateCN = '汽车保养';
            break;
          }
          case SERVICE_SUB_CATEGORY.DIANZIWEIXIU: {
            cateCN = '电子维修';
            break;
          }
          case SERVICE_SUB_CATEGORY.BANJIAJIESONG: {
            cateCN = '搬家接送';
            break;
          }
          case SERVICE_SUB_CATEGORY.PEIXUNJIAOXUE: {
            cateCN = '培训教育';
            break;
          }
          case SERVICE_SUB_CATEGORY.KUAIJIELVSHI: {
            cateCN = '会计律师';
            break;
          }
          case SERVICE_SUB_CATEGORY.FANYIHUANHUI: {
            cateCN = '翻译换汇';
            break;
          }
          case SERVICE_SUB_CATEGORY.MEIRONGYILIAO: {
            cateCN = '美容医疗';
            break;
          }
          case SERVICE_SUB_CATEGORY.DAIGOUWULIU: {
            cateCN = '代购物流';
            break;
          }
          case SERVICE_SUB_CATEGORY.MENGCHONGLVZHI: {
            cateCN = '萌宠绿植';
            break;
          }
          case SERVICE_SUB_CATEGORY.GUANGGAOSHEJI: {
            cateCN = '广告设计';
            break;
          }
          case SERVICE_SUB_CATEGORY.WUJIANJICUN: {
            cateCN = '物件寄存';
            break;
          }
          case SERVICE_SUB_CATEGORY.QITAFUWU: {
            cateCN = '其他服务';
            break;
          }
          case SERVICE_SUB_CATEGORY.QIUZHU: {
            cateCN = '求助';
            break;
          }
          default: {
            cateCN = '本地服务';
          }
        }
        break;
      }
      case TOPIC_CATEGORY.USED: {
        switch (sub_category_name) {
          case ERSHOU_SUB_CATEGORY.XINHUOJIAOYI: {
            cateCN = '新货交易';
            break;
          }
          case ERSHOU_SUB_CATEGORY.ERSHOUJIAOYI: {
            cateCN = '二手交易';
            break;
          }
          case ERSHOU_SUB_CATEGORY.MIANFEIZENGSONG: {
            cateCN = '免费赠送';
            break;
          }
          case ERSHOU_SUB_CATEGORY.QIUGOU: {
            cateCN = '求购';
            break;
          }
          default: {
            cateCN = '新货二手';
          }
        }
        break;
      }
      case TOPIC_CATEGORY.ACTIVITY: {
        cateCN = '同城活动';
        break;
      }
      case TOPIC_CATEGORY.TRIP: {
        cateCN = '旅游门票';
        break;
      }
      case TOPIC_CATEGORY.BUSINESS: {
        cateCN = '生意买卖';
        break;
      }
      default: {
        cateCN = '全部';
      }
    }
    return '在' + cateCN + '分类中搜索';
  }
  return defaultHolder;
};

const HeaderMobile = inject(`filterStore`)(
  observer(props => {
    const { onSearch, allowGoBack = false, showSearch = true } = props;
    const { category_name } = props.filterStore;
    return (
      <Styled>
        <div className="mh_fixed_header">
          {allowGoBack ? (
            <div className="left" onClick={Router.back()}>
              <Icon size="lg" type="left" />
            </div>
          ) : (
            <div>
              <Link href="/">
                <img src={logo} className="sm-logo" alt="" />
              </Link>
            </div>
          )}
          {showSearch && (
            <div className="title-container" onClick={onSearch}>
              <Icon size="xs" type="search" />
              {generalPlaceHolder(category_name)}
            </div>
          )}
        </div>
        <div className="mh__empty_block" />
      </Styled>
    );
  })
);

const Styled = styled.div`
  @media (min-width: ${props => props.theme.desktopMinWidth}) {
    .mh_fixed_header {
      width: ${props => props.theme.maxWidth};
    }
  }

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    .mh_fixed_header {
      width: 100%;
    }
  }
  .mh_fixed_header {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 0.5em 1em;
    height: 55px;
    z-index: 100;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    .right {
      height: 44px;
      flex-grow: 0;
      align-items: center;
      display: flex;
    }

    .sm-logo {
      width: 56px;
      height: 24px;
      margin-left: 10px;
      margin-top: 16px;
      margin-bottom: 15px;
    }

    @media (max-width: 320px) {
      .title-container {
        font-size: 12px;
      }
    }

    .title-container {
      //width: auto;
      flex-grow: 1;
      height: 40px;
      line-height: 40px;
      border: 1px solid #ccc;
      border-radius: 3px;
      //margin: 5px 10px;
      margin: 8px 10px 7px 24px;
      color: #ccc;
      display: flex;
      align-items: center;
      .am-icon {
        float: left;
        margin: 6px;
      }
      .title {
        color: black;
        display: inline-block;
      }
    }
  }
  .mh__empty_block {
    height: 55px;
  }
`;

export default HeaderMobile;
