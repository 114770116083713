import React, { Component } from 'react';
import Router from 'next/router';
import { Grid, Modal } from 'antd-mobile';
import GroupCard from './GroupCard';
import { inject, observer } from 'mobx-react';
import SectionHeader from '../../category/common/SectionHeader';
import styled from 'styled-components';

const SMGrid = styled(Grid)`
  background-color: white;
  height: 140px;
  .am-flexbox {
    background-color: transparent !important;
    .am-grid-item-content {
      padding: 0 !important;
    }
  }
`;

@inject('groupStore')
@observer
class GroupCardList extends Component {
  state = {
    showAddGroupModal: false,
    groupId: null
  };

  async componentDidMount() {
    const { groupStore, initData } = this.props;
    // if (initData) {
    //   groupStore.initWithGroupsInfo(initData);
    // } else {
    if (groupStore.currentCategoryIndex === this.props.categoryIndex) {
      await groupStore.getCurrentGroups();
    } else {
      groupStore.setCurrentCategory(this.props.categoryIndex);
    }
    // }
  }

  onClicked = async item => {
    const { groupStore } = this.props;
    if (item.inGroup) {
      Router.push(`/group/detail/[groupId]`, `/group/detail/${item.id}`);
    } else {
      await groupStore.joinGroup(item.id);
      this.setState({
        showAddGroupModal: true,
        groupId: item.id
      });
      await this.props.groupStore.getCurrentGroups();
    }
  };

  render() {
    const { groupStore } = this.props;
    if (groupStore.currentGroups.length > 0) {
      return (
        <>
          <SectionHeader
            contentText={this.props.title ? this.props.title : '推荐群'}
            rightBtnText={'查看全部'}
            rightBtnOnClick={() => {
              groupStore.setCurrentGroupTab(1);
              Router.push('/group');
            }}
          />
          <SMGrid
            data={groupStore.currentGroups.slice(0, 5)}
            hasLine={false}
            isCarousel
            carouselMaxRow={1}
            columnNum={1}
            cellAlign={'left'}
            autoplay={false}
            dots={false}
            square
            activeStyle={false}
            slideWidth="150px"
            itemStyle={{ height: 140, width: 150 }}
            renderItem={(el, index) => (
              <GroupCard
                onClicked={() => {
                  this.onClicked(el);
                }}
                data={el}
                key={el.id}
              />
            )}
          />
          <Modal
            visible={this.state.showAddGroupModal}
            transparent
            closable
            onClose={() => {
              this.setState({
                showAddGroupModal: false
              });
            }}
            maskClosable={false}
            title="成功加入小组"
            footer={[
              {
                text: '进入小组',
                onPress: () => {
                  this.setState({
                    showAddGroupModal: false
                  });
                  if (this.state.groupId) {
                    Router.push(
                      `/group/detail/[groupId]`,
                      `/group/detail/${this.state.groupId}`
                    );
                  }
                }
              }
            ]}
          >
            <div>可以进入小组查看小组信息了</div>
          </Modal>
        </>
      );
    } else {
      return null;
    }
  }
}

export default GroupCardList;
