import React, { Component } from 'react';
import { Toast, Modal } from 'antd-mobile';
import ReportForm from '../forms/Report';
import api from '../api';
class Report extends Component {
  constructor() {
    super();
    this.state = {
      modal: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  handleOk = () => {
    this.setState({
      modal: false
    });
  };
  async onSubmit(form) {
    const { data } = await api.topic.report(this.props.topicId, form);
    if (data && !data.code) {
      Toast.success('举报信息提交成功');
    }
    this.setState({
      modal: false
    });
  }
  render() {
    const { modal } = this.state;
    return (
      <span>
        <a onClick={() => this.setState({ modal: true })}>
          {' '}
          &nbsp; 举报 &nbsp;{' '}
        </a>
        <Modal
          title={'举报'}
          visible={modal}
          onClose={this.handleOk}
          transparent={true}
          popup={false}
        >
          {modal && <ReportForm onSubmit={this.onSubmit} />}
        </Modal>
      </span>
    );
  }
}

export default Report;
