import React, {Component} from 'react';
import {Button, TextareaItem, Toast} from 'antd-mobile';


class ReportForm extends Component{
  constructor(){
    super();
    this.state = {
      content: ""
    };
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(){
    if (this.state.content.trim().length > 0) {
      this.props.onSubmit({
        content: this.state.content
      })
    }else {
      Toast.fail("请输入举报内容")
    }
  }

  render(){
    return (
      <div>
        <TextareaItem
          rows={4}
          count={1000}
          placeholder="举报内容"
          value={this.state.content}
          onChange={e => {
            this.setState({
              content: e
            });
          }
          }
        />
        <Button type="primary" style={{marginTop: '20px'}} onClick={this.onSubmit}>提交</Button>
      </div>

    );
  }
}

export default ReportForm;
