// import newItem from '../../static/category/usedItems/group9@3x.png';
// import usedItem from '../../static/category/usedItems/group10@3x.png';
// import freeItem from '../../static/category/usedItems/group12@3x.png';
// import buyItem from '../../static/category/usedItems/group11@3x.png';
// import phone from '../../static/category/usedItems/rectangleCopy7@3x.png';
// import refrig from '../../static/category/usedItems/rectangleCopy6@3x.png';
// import computer from '../../static/category/usedItems/rectangleCopy8@3x.png';
// import cloth from '../../static/category/usedItems/rectangleCopy9@3x.png';
// import makeup from '../../static/category/usedItems/rectangleCopy10@3x.png';
// import game from '../../static/category/usedItems/rectangleCopy13@3x.png';
// import office from '../../static/category/usedItems/rectangleCopy12@3x.png';
// import mother from '../../static/category/usedItems/rectangleCopy11@3x.png';
// import doggie from '../../static/category/usedItems/rectangleCopy14@3x.png';
// import plant from '../../static/category/usedItems/rectangleCopy15@3x.png';
// import sport from '../../static/category/usedItems/rectangleCopy16@3x.png';
// import other from '../../static/category/usedItems/rectangleCopy17@3x.png';

export const ERSHOU_SUB_CATEGORY = {
  XINHUOJIAOYI: 'xinhuojiaoyi',
  ERSHOUJIAOYI: 'ershoujiaoyi',
  MIANFEIZENGSONG: 'mianfeizengsong',
  QIUGOU: 'qiugou'
};

export const level_2 = [
  {
    label: '新货交易',
    value: ERSHOU_SUB_CATEGORY.XINHUOJIAOYI,
    icon: '../../static/category/usedItems/group9@3x.png'
  },
  {
    label: '二手交易',
    value: ERSHOU_SUB_CATEGORY.ERSHOUJIAOYI,
    icon: '../../static/category/usedItems/group10@3x.png'
  },
  {
    label: '免费赠送',
    value: ERSHOU_SUB_CATEGORY.MIANFEIZENGSONG,
    icon: '../../static/category/usedItems/group12@3x.png'
  },
  {
    label: '求购信息',
    value: ERSHOU_SUB_CATEGORY.QIUGOU,
    icon: '../../static/category/usedItems/group11@3x.png'
  }
];

export const ITEM_CATEGORY = [
  {
    label: '其他百货',
    value: '其他百货',
    icon: '../../static/category/usedItems/rectangleCopy17@3x.png'
  },
  {
    label: '手机周边',
    value: '手机周边',
    icon: '../../static/category/usedItems/rectangleCopy7@3x.png'
  },
  {
    label: '家居家电',
    value: '家居家电',
    icon: '../../static/category/usedItems/rectangleCopy6@3x.png'
  },
  {
    label: '电脑数码',
    value: '电脑数码',
    icon: '../../static/category/usedItems/rectangleCopy8@3x.png'
  },
  {
    label: '衣包饰品',
    value: '衣包饰品',
    icon: '../../static/category/usedItems/rectangleCopy9@3x.png'
  },
  {
    label: '美容护肤',
    value: '美容护肤',
    icon: '../../static/category/usedItems/rectangleCopy10@3x.png'
  },
  {
    label: '游戏影视',
    value: '游戏影视',
    icon: '../../static/category/usedItems/rectangleCopy13@3x.png'
  },
  {
    label: '学习办公',
    value: '学习办公',
    icon: '../../static/category/usedItems/rectangleCopy12@3x.png'
  },
  {
    label: '母婴用品',
    value: '母婴用品',
    icon: '../../static/category/usedItems/rectangleCopy11@3x.png'
  },
  {
    label: '宝贝萌宠',
    value: '宝贝萌宠',
    icon: '../../static/category/usedItems/rectangleCopy14@3x.png'
  },
  {
    label: '果蔬绿植',
    value: '果蔬绿植',
    icon: '../../static/category/usedItems/rectangleCopy15@3x.png'
  },
  {
    label: '户外运动',
    value: '户外运动',
    icon: '../../static/category/usedItems/rectangleCopy16@3x.png'
  }
];

export const used = [
  { label: '9成新', value: '9成新' },
  { label: '8成新', value: '8成新' },
  { label: '7成新', value: '7成新' },
  { label: '6成新', value: '6成新' },
  { label: '5成新', value: '5成新' },
  { label: '4成新', value: '4成新' },
  { label: '3成新', value: '3成新' },
  { label: '2成新', value: '2成新' },
  { label: '1成新', value: '1成新' }
];

export const pickup = [
  { label: '配送', value: '配送' },
  { label: '自提', value: '自提' }
];
