import React from 'react';
import { CheliangMetaContainer } from './CheliangMetaStyle';
import numeral from 'numeral';
import vehicle_features from '../../../constants/vehicle_features';
import MetaContent from './common/MetaContent';
import SectionHeader from './common/SectionHeader';

export const locationGenerator = (topic, reverse) => {
  const { city, area, sub_area } = topic;
  let area_text_array = [];
  if (city) {
    const { chinese_name: city_chinese, name: city_name } = city;
    if (reverse) {
      area_text_array = [city_name, ...area_text_array];
    } else {
      area_text_array = [
        ...area_text_array,
        city_chinese ? city_chinese : city_name
      ];
    }
  }

  if (area) {
    const { chinese_name: area_chinese, name: area_name } = area;
    if (reverse) {
    } else {
      area_text_array = [
        ...area_text_array,
        area_chinese ? area_chinese : area_name
      ];
    }
  }

  if (sub_area) {
    const { chinese_name: sub_area_chinese, name: sub_area_name } = sub_area;
    if (reverse) {
      area_text_array = [sub_area_name, ...area_text_array];
    } else {
      area_text_array = [
        ...area_text_array,
        sub_area_chinese ? sub_area_chinese : sub_area_name
      ];
    }
  }
  return area_text_array.join(', ');
};

const CheliangMeta = props => {
  const { topic } = props;
  const { content } = topic;
  const {
    transmission,
    cc,
    fuel_type,
    year,
    kilometre,
    origin_country,
    import_country,
    nz_register_date,
    features,
    make,
    model,
    content: metaContent
  } = topic.meta || {};
  const carContent = content ? content : metaContent;

  return (
    <CheliangMetaContainer>
      <div className="cheliang-specs">
        <div className="spec-container">
          <p>
            <i className={`iconfont icon-chepai`} />
          </p>
          <span>{make ? make.toUpperCase() : '-'}</span>
          <span>{model ? model.toUpperCase() : '-'}</span>
        </div>
        <div className="spec-container">
          <p>
            <i className={`iconfont icon-rili3`} />
          </p>
          <span>{year ? `${year}年` : '-'}</span>
        </div>
        <div className="spec-container">
          <p>
            <i className={`iconfont icon-licheng`} />
          </p>
          <span>
            {kilometre
              ? `${numeral(kilometre / 10000).format('0,0.[0]')}万公里`
              : `-`}
          </span>
        </div>
        <div className="spec-container">
          <p>
            <i className={`iconfont icon-engine`} />
          </p>
          <span>{cc ? `${numeral(cc / 1000).format('0.0')}L` : `-`}</span>
        </div>
        <div className="spec-container">
          <p>
            <i className={`iconfont icon-jiayouzhan1`} />
          </p>
          <span>{fuel_type ? fuel_type : `-`}</span>
        </div>
        <div className="spec-container">
          <p>
            <i className={`iconfont icon-typecar`} />
          </p>
          <span>{transmission ? transmission : '-'}</span>
        </div>
        {/* specs with text title*/}
        <div className="spec-container">
          <p className="spec-title">原产国</p>
          <span>{origin_country ? origin_country : `-`}</span>
        </div>
        <div className="spec-container">
          <p className="spec-title">进口国</p>
          <span>{import_country ? import_country : `-`}</span>
        </div>
        <div className="spec-container">
          <p className="spec-title">NZ注册</p>
          <span>{nz_register_date ? nz_register_date : '-'}</span>
        </div>
      </div>

      <SectionHeader title={'所在地区'} value={locationGenerator(topic)} />

      {features && features.length > 0 && (
        <div className="specs-highlight">
          <span>配置亮点</span>
          <div className="feature-container">
            {features.map(feature => {
              return (
                <div key={vehicle_features[feature].label} className="feature">
                  <span>{`${vehicle_features[feature].label}`}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {carContent && <MetaContent content={carContent} topic={topic} />}
    </CheliangMetaContainer>
  );
};

export default CheliangMeta;
