import React, { Component } from 'react';
import { ListView } from 'antd-mobile';
import FeedsCard from '../container/feedsCard/FeedsCard';
import { inject, observer } from 'mobx-react';
import ArticleFeedCard from '../container/feedsCard/ArticleFeedCard';

@inject('uiStore')
@observer
class FeedList extends Component {
  loading = false;

  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2
    });
    this.state = {
      dataSource: dataSource.cloneWithRows([])
    };
  }

  componentDidMount() {
    if (this.props.feedStore.data.length > 0) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(
          this.props.feedStore.data.toJS()
        )
      });
    } else {
      this.onEndReached();
    }
  }

  separator = (sectionID, rowID) => (
    <div
      key={`${sectionID}-${rowID}`}
      style={{
        backgroundColor: '#F5F5F9',
        height: 8,
        borderTop: '1px solid #ECECED',
        borderBottom: '1px solid #ECECED'
      }}
    />
  );

  row = rowData => {
    return rowData.type === 'topic' ? (
      <FeedsCard topic={rowData} />
    ) : (
      <ArticleFeedCard article={rowData} />
    );
  };

  renderFooter = () => {
    return (
      <div style={{ padding: 20, textAlign: 'center' }}>{'加载中...'}</div>
    );
  };

  onEndReached = async () => {
    const { fetchData } = this.props.feedStore;
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.loading) {
      return;
    }
    this.loading = true;
    const data = await fetchData();
    this.setState({
      dataSource: this.state.dataSource.cloneWithRows(data)
    });
    this.loading = false;
  };

  render() {
    return (
      <ListView
        ref={el => (this.lv = el)}
        initialListSize={this.props.feedStore.data.length || 10}
        dataSource={this.state.dataSource}
        renderFooter={this.renderFooter}
        renderRow={this.row}
        renderSeparator={this.separator}
        className="am-list"
        pageSize={10}
        useBodyScroll
        scrollRenderAheadDistance={500}
        onEndReached={this.onEndReached}
        onEndReachedThreshold={10}
      />
    );
  }
}

export default FeedList;
