import React from 'react';
import { inject, observer } from 'mobx-react';
import Link from 'next/link';
import { getBannerImage, getHomeBannerImage } from '../../util';
import { getEnv } from '../../utilNoSSR';
import styled from 'styled-components';
import Slider from 'react-slick';

const Slide = styled.div`
  background-color: white;
  overflow: hidden;
`;

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const CategoryBanners = inject('configStore')(
  observer(props => {
    const { banners } = props.configStore;
    const { category } = props;
    let cateBanners = banners[category];
    if (cateBanners) {
      cateBanners = cateBanners.map(item => {
        let url = item.url.replace('?id=', '/');
        let href = null;
        if (url) {
          if (url.includes('/topic')) {
            href = '/topic/[id]';
          } else if (url.includes('/article')) {
            href = '/article/[id]';
          } else if (url.includes('/merchant')) {
            href = '/merchant/[merchantId]';
            url = url.substring(url.indexOf('/merchant/'), url.length);
          } else if (url === '#') {
            href = '/';
          } else {
            href = url;
          }
        }
        return { ...item, href, url };
      });
    }

    return cateBanners && cateBanners.length > 0 ? (
      <div className="slides">
        {cateBanners.length > 1 ? (
          <Slider
            dots
            initialSlide={0}
            autoplay
            autoplaySpeed={5000}
            infinite={true}
            adaptiveHeight={true}
          >
            {shuffle(cateBanners).map(banner => (
              <Link href={banner.href} as={banner.url} key={banner.id}>
                <Slide className="slide">
                  <img
                    src={
                      category === 'home'
                        ? getHomeBannerImage(banner.image)
                        : getBannerImage(banner.image)
                    }
                    alt=""
                  />
                </Slide>
              </Link>
            ))}
          </Slider>
        ) : (
          <Link href={cateBanners[0].href} as={cateBanners[0].url}>
            <Slide className="slide">
              <img
                src={
                  category === 'home'
                    ? getHomeBannerImage(cateBanners[0].image)
                    : getBannerImage(cateBanners[0].image)
                }
                alt=""
              />
            </Slide>
          </Link>
        )}
      </div>
    ) : (
      getEnv() !== 'miniProgram' && (
        <ins
          className="adsbygoogle"
          style={{
            display: 'block',
            width: '320px',
            height: '100px',
            margin: 'auto'
          }}
          data-ad-client="ca-pub-6984675665005390"
          data-ad-slot="1541492823"
        ></ins>
      )
    );
  })
);

export default CategoryBanners;
