import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import styled from 'styled-components';
import { JOB_SUB_CATEGORY, salary } from '../constants/category/job';
import Report from '../components/Report';
import { renderTitle } from '../container/feedsCard/FeedsCard';
import { TOPIC_CATEGORY } from '../mobx/TopicStore';

const TopicTitleContainer = styled.div`
  width: 100%;
  min-height: 77px;
  background-color: white;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .topic-title-content {
    line-height: 20px;
    width: 355px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0 0 0 0.65);
    padding: 8px 10px 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .topic-title-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .topic-title-price {
      width: 130px;
      height: 28px;
      line-height: 28px;
      margin-left: 10px;
      font-size: 20px;
      color: ${props => props.theme.primaryColor};
      overflow: hidden;
      margin-bottom: 7px;
    }
    .topic-title-additional {
      display: flex;
      flex-direction: row;
      .topic-title-view-count {
        max-width: 70px;
        height: 28px;
      }
      .topic-title-report {
        width: 45px;
        height: 28px;
      }
      .topic-title-view-count,
      .topic-title-report {
        line-height: 28px;
        margin-right: 10px;
        font-size: 14px;
        color: rgb(155 155 155);
        overflow: hidden;
        margin-bottom: 7px;
      }
    }
  }
`;

const renderSalaryTitle = (topic, value) => {
  if (topic.sub_category_name === JOB_SUB_CATEGORY.QIUZHI) return '';
  const _value = salary.filter(s => s.value === value)[0];
  return _value && _value.label;
};

export const handleTitle = topic => {
  if (topic.category_name === TOPIC_CATEGORY.USED) {
    return topic.meta.name;
  } else if (topic.category_name === TOPIC_CATEGORY.SOCIAL) {
    return '';
  } else {
    return topic.title;
  }
};

function TopicTitle(props) {
  const { topic } = props;

  return (
    <TopicTitleContainer>
      <div className="topic-title-content">
        {renderTitle(
          handleTitle(topic),
          topic.category_name,
          topic.sub_category_name
        )}
      </div>
      <div className="topic-title-info">
        <div className="topic-title-price">
          {topic.category_name === 'ershou' &&
            topic.meta.type === '免费送' &&
            ' 免费赠送'}
          {topic.category_name === 'gongzuo' &&
            topic.meta.salary &&
            renderSalaryTitle(topic, topic.meta.salary)}
          {topic.meta.price
            ? `$${numeral(topic.meta.price).format('0,0')}`
            : topic.meta.rent_per_week
            ? `$${numeral(topic.meta.rent_per_week).format('0,0')}`
            : ''}
        </div>
        <div className="topic-title-additional">
          {topic.view_count && (
            <div className="topic-title-view-count">{topic.view_count}浏览</div>
          )}
          <div className="topic-title-report">
            <Report topicId={topic.id} />
          </div>
        </div>
      </div>
    </TopicTitleContainer>
  );
}

TopicTitle.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  rent_per_week: PropTypes.number,
  area_text: PropTypes.string
};

export default TopicTitle;
