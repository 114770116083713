// import jiaZhengFuWu from '../../static/category/localServices/01.png';
// import zhuangXiuWeiHu from '../../static/category/localServices/02.png';
// import qiCheBaoYang from '../../static/category/localServices/03.png';
// import dianZiWeiXiu from '../../static/category/localServices/04.png';
// import banJiaJieSong from '../../static/category/localServices/05.png';
// import peiXunJiaoXue from '../../static/category/localServices/06.png';
// import kuaiJiLvShi from '../../static/category/localServices/07.png';
// import fanYiHuanHui from '../../static/category/localServices/08.png';
// import meiRongYiLiao from '../../static/category/localServices/09.png';
// import daiGouWuLiu from '../../static/category/localServices/10.png';
// import mengChongLvZhi from '../../static/category/localServices/11.png';
// import wupinjicun from '../../static/category/localServices/wupinjicun.png';
// import guangGaoSheJi from '../../static/category/localServices/12.png';
// import others from '../../static/category/localServices/14.png';
// import qiuZhu from '../../static/category/localServices/15.png';

export const SERVICE_SUB_CATEGORY = {
  JIAZHENGFUWU: 'jiazhengfuwu',
  ZHUANGXIUWEIHU: 'zhuangxiuweihu',
  QICHEBAOYANG: 'qichebaoyang',
  DIANZIWEIXIU: 'dianziweixiu',
  BANJIAJIESONG: 'banjiajiesong',
  PEIXUNJIAOXUE: 'peixunjiaoxue',
  KUAIJIELVSHI: 'kuaijilvshi',
  FANYIHUANHUI: 'fanyihuanhui',
  MEIRONGYILIAO: 'meirongyiliao',
  DAIGOUWULIU: 'daigouwuliu',
  MENGCHONGLVZHI: 'mengchonglvzhi',
  GUANGGAOSHEJI: 'guanggaosheji',
  WUJIANJICUN: 'wujianjicun',
  QITAFUWU: 'qitafuwu',
  QIUZHU: 'qiuzhu'
};

export const level_2 = [
  {
    label: '求助',
    value: SERVICE_SUB_CATEGORY.QIUZHU,
    icon: '../../static/category/localServices/15.png'
  },
  {
    label: '家政服务',
    value: SERVICE_SUB_CATEGORY.JIAZHENGFUWU,
    icon: '../../static/category/localServices/01.png'
  },
  {
    label: '装修维护',
    value: SERVICE_SUB_CATEGORY.ZHUANGXIUWEIHU,
    icon: '../../static/category/localServices/02.png'
  },
  {
    label: '汽车保养',
    value: SERVICE_SUB_CATEGORY.QICHEBAOYANG,
    icon: '../../static/category/localServices/03.png'
  },
  {
    label: '电子维修',
    value: SERVICE_SUB_CATEGORY.DIANZIWEIXIU,
    icon: '../../static/category/localServices/04.png'
  },
  {
    label: '搬家接送',
    value: SERVICE_SUB_CATEGORY.BANJIAJIESONG,
    icon: '../../static/category/localServices/05.png'
  },
  {
    label: '培训教学',
    value: SERVICE_SUB_CATEGORY.PEIXUNJIAOXUE,
    icon: '../../static/category/localServices/06.png'
  },
  {
    label: '会计律师',
    value: SERVICE_SUB_CATEGORY.KUAIJIELVSHI,
    icon: '../../static/category/localServices/07.png'
  },
  {
    label: '翻译换汇',
    value: SERVICE_SUB_CATEGORY.FANYIHUANHUI,
    icon: '../../static/category/localServices/08.png'
  },
  {
    label: '美容医疗',
    value: SERVICE_SUB_CATEGORY.MEIRONGYILIAO,
    icon: '../../static/category/localServices/09.png'
  },
  {
    label: '代购物流',
    value: SERVICE_SUB_CATEGORY.DAIGOUWULIU,
    icon: '../../static/category/localServices/10.png'
  },
  {
    label: '萌宠绿植',
    value: SERVICE_SUB_CATEGORY.MENGCHONGLVZHI,
    icon: '../../static/category/localServices/11.png'
  },
  {
    label: '广告设计',
    value: SERVICE_SUB_CATEGORY.GUANGGAOSHEJI,
    icon: '../../static/category/localServices/12.png'
  },
  {
    label: '物件寄存',
    value: SERVICE_SUB_CATEGORY.WUJIANJICUN,
    icon: '../../static/category/localServices/wupinjicun.png'
  },
  {
    label: '其它服务',
    value: SERVICE_SUB_CATEGORY.QITAFUWU,
    icon: '../../static/category/localServices/14.png'
  }
];
