// import fullTime from '../../static/category/recruitment/2_01.png';
// import partTime from '../../static/category/recruitment/2_02.png';
// import qiuZhi from '../../static/category/recruitment/2_03.png';
// import xingZheng from '../../static/category/recruitment/3_01.png';
// import xiaoShou from '../../static/category/recruitment/3_02.png';
// import shiChang from '../../static/category/recruitment/3_03.png';
// import jinRong from '../../static/category/recruitment/3_04.png';
// import laoGong from '../../static/category/recruitment/3_05.png';
// import canYin from '../../static/category/recruitment/3_06.png';
// import wangLuo from '../../static/category/recruitment/3_07.png';
// import jianZhu from '../../static/category/recruitment/3_08.png';
// import yiLiao from '../../static/category/recruitment/3_09.png';

export const JOB_SUB_CATEGORY = {
  ZHAOPIN: 'zhaopin',
  QIUZHI: 'qiuzhi'
};

export const WORK_TYPE = {
  FULL_TIME: 'fulltime',
  PART_TIME: 'parttime',
  INTERN: 'intern',
  VOLUNTEER: 'volunteer'
};

export const JobHomepageMenu = [
  {
    label: '全职职位',
    value: JOB_SUB_CATEGORY.ZHAOPIN,
    type: WORK_TYPE.FULL_TIME,
    chnValue: '全职',
    icon: '../../static/category/recruitment/2_01.png'
  },
  {
    label: '兼职职位',
    value: JOB_SUB_CATEGORY.ZHAOPIN,
    type: WORK_TYPE.PART_TIME,
    chnValue: '兼职',
    icon: '../../static/category/recruitment/2_02.png'
  },
  {
    label: '求职信息',
    value: JOB_SUB_CATEGORY.QIUZHI,
    type: null,
    chnValue: null,
    icon: '../../static/category/recruitment/2_03.png'
  }
];

export const JOB_ROLES = [
  {
    label: '行政/文职/管理',
    value: '行政/文职/管理',
    icon: '../../static/category/recruitment/3_01.png'
  },
  {
    label: '销售/客服/运营',
    value: '销售/客服/运营',
    icon: '../../static/category/recruitment/3_02.png'
  },
  {
    label: '市场/编辑/设计',
    value: '市场/编辑/设计',
    icon: '../../static/category/recruitment/3_03.png'
  },
  {
    label: '金融/法律/教育',
    value: '金融/法律/教育',
    icon: '../../static/category/recruitment/3_04.png'
  },
  {
    label: '劳工/物流/汽修',
    value: '劳工/物流/汽修',
    icon: '../../static/category/recruitment/3_05.png'
  },
  {
    label: '餐饮/家政/服务',
    value: '餐饮/家政/服务',
    icon: '../../static/category/recruitment/3_06.png'
  },
  {
    label: '网络/IT/通信',
    value: '网络/IT/通信',
    icon: '../../static/category/recruitment/3_07.png'
  },
  {
    label: '建筑/水电/维修',
    value: '建筑/水电/维修',
    icon: '../../static/category/recruitment/3_08.png'
  },
  {
    label: '医疗/美容/休闲',
    value: '医疗/美容/休闲',
    icon: '../../static/category/recruitment/3_09.png'
  }
];

export const workType = [
  {
    label: '全职',
    value: '全职'
  },
  {
    label: '兼职',
    value: '兼职'
  },
  {
    label: '实习生',
    value: '实习生'
  },
  {
    label: '志愿者',
    value: '志愿者'
  }
];

export const salary = [
  {
    label: '最低工资',
    value: '最低工资'
  },
  {
    label: '高于最低工资',
    value: '高于最低工资'
  },
  {
    label: '面议',
    value: '面议'
  }
];

export const visa = [
  {
    label: '不限',
    value: 'any'
  },
  {
    label: '工签',
    value: 'work'
  },
  {
    label: 'PR',
    value: 'PR'
  }
];
